import { Icon } from "@components/Icons/index"
import React from "react"

const tableColumns = ({ handleEditBanner, handleDeleteBanner }) => [
  {
    title: "Nome da página",
    dataIndex: "pageName",
    key: "pageName",
    render: (text, record) => (
      <label
        className="normal pointer french-rose"
        onClick={() => handleEditBanner(record)}
      >
        {text}
      </label>
    ),
  },
  {
    title: "Ação",
    dataIndex: "action",
    render: (text, record) => (
      <Icon
        className="pointer normal"
        name="IcDelete"
        onClick={() => handleDeleteBanner(record)}
      />
    ),
    hideInSearch: true,
  },
]

export default tableColumns

import CustomProTable from "@components/CustomProTable"
import { useStores } from "@hooks/use-stores"
import tableColumns from "@modules/Banners/components/ListGeneralBanners/tableColumns"
import { Button, Col, Modal } from "antd"
import { navigate } from "gatsby"
import React, { useCallback, useState } from "react"

const ListGeneralBanners = () => {
  const { banners } = useStores()
  const { getAllGeneralBanners, deleteGeneralBanner } = banners

  const [generalBanners, setGeneralBanners] = useState([])
  const [totalItems, setTotalItems] = useState(null)

  const getGeneralBanners = useCallback(async (params) => {
    const { data, total } = await getAllGeneralBanners(params)

    setGeneralBanners(data)
    setTotalItems(total)

    return { data, total }
  })

  const handleEditBanner = (data) => {
    const path = `/admin/banners-gerais/editar/${data.id.toString()}`
    navigate(path, {
      state: { dataBanner: data, id: data.id, edit: true },
    })
  }

  const handleDeleteBanner = async ({ id }) => {
    Modal.confirm({
      title: "Você tem certeza que deseja deletar esse item?",
      onOk: async () => {
        await deleteGeneralBanner(id)
        await getGeneralBanners()
      },
      okType: "danger",
      okText: "Sim",
      cancelText: "Não",
    })
  }

  return (
    <div>
      <Col span={24} className="mv24">
        <div className="geral-table bannerGeneralTable">
          <CustomProTable
            headerTitle="Listagem de banners"
            columns={tableColumns({ handleEditBanner, handleDeleteBanner })}
            dataSource={generalBanners}
            request={getGeneralBanners}
            rowKey="id"
            total={totalItems}
            toolBarRender={() => [
              <Button
                key="button"
                type="primary"
                className="button-essay"
                onClick={() => navigate("/admin/banners-gerais/cadastrar")}
              >
                Cadastrar banner
              </Button>,
            ]}
          />
        </div>
      </Col>
    </div>
  )
}

export default ListGeneralBanners

import { LayoutAdmin } from "@components/LayoutAdmin"
import ListGeneralBanners from "@modules/Banners/components/ListGeneralBanners"
import React from "react"

const AdminGeneralBanners = () => {
  return (
    <LayoutAdmin title="Banners" subTitle="Visualize e gerencie os banners.">
      <ListGeneralBanners />
    </LayoutAdmin>
  )
}

export default AdminGeneralBanners
